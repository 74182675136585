import Vue from 'vue'
import VueRouter from 'vue-router'
//import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      title: 'のぞみクリニック築地 | 一般内科、Glp-1受容体作動薬、発熱外来、エクソソーム、プラセンタ | 土曜日診療可能 | 中国語対応',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。中国語対応スタッフも常駐しています。',
    },
    component: () => import('../views/Home')
  },
  {
    path: '/information',
    meta: {
      title: 'お知らせ | のぞみクリニック築地 | 中国語対応',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。中国語対応スタッフも常駐しています。',
    },
    component: () => import('../views/Information')
  },
  {
    path: '/infoitem',
    meta: {title: "お知らせ | のぞみクリニック築地",
      description:'',
      keywords:''},
    component: () => import('../views/InfoItem')
  },
  {
    path: '/about',
    name: 'About',
    meta: {title: '当院について | のぞみクリニック築地 | 中国語対応',
      description:'一般内科として適切な医療を受けるための最初の窓口としての役割を担うため、地域の皆様の“かかりつけ医”となれるよう、親身で丁寧な初期診療を心がけております。中国語対応スタッフも常駐しています。',},
    component: () => import('../views/About.vue')
  },
  {
    path: '/access',
    meta: {title: 'アクセス | のぞみクリニック築地 | 中国語対応',
      description:'東京都中央区築地7-11-5 中銀ベル築地マンシオン2階、東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分です。',
      keywords:'築地駅より4分,中銀ベル築地,のぞみクリニック築地'},
    component: () => import('../views/Access')
  },
  {
    path: '/question',
    meta: {title: 'よくある質問｜のぞみクリニック築地',
      description:'',
      keywords:''},
    component: () => import('../views/Question')
  },
  {
    path: '/sending',
    meta: {title: 'お問い合わせ | のぞみクリニック築地 | 中国語対応',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。中国語対応スタッフも常駐しています。',},
    component: () => import('../views/Sending')
  },
  {
    path: '/department/telemedicine',
    meta: {title: 'WEB予約| のぞみクリニック築地 | 中国語対応',
      description:'当院では、Web予約も承っております。三つの診療希望日をご記入いただき、簡単に予約できます。'},
    component: () => import('../views/department/Telemedicine_New')
  },
  {
    path: '/department/generalMedicine',
    meta: {title: '一般内科 | のぞみクリニック築地 | 中国語対応',
      description:'頭痛、めまい、かぜ症状、吐き気、腹痛、下痢など急性症状、便秘症、疲労感や倦怠感、花粉症などの一般的な疾患について診療します。中国語対応スタッフも常駐しています。'},
    component: () => import('../views/department/generalMedicine')
  },

  {
    path: '/department/fever',
    meta:{title:'発熱外来・新型コロナウイルスPCR検査 | のぞみクリニック築地 | 中国語対応',
      description:'当院は東京都感染症診療協力医療機関で、発熱外来や新型コロナウイルス関連のPCR検査、抗原定性検査を提供しています。中国語対応スタッフも常駐しています。'},
    component:() => import('../views/department/Fever')
  },
  {
    path: '/department/corona',
    meta:{title:'コロナ予防接種 | のぞみクリニック築地 | 中国語対応',
      description:'新型コロナワクチンの予防接種は、令和6年4月以降は、65歳以上の方および60～64歳で重症化リスクの高い方を対象とした定期接種に変わります。',
      keywords:'コロナ予防接種,新型コロナワクチン,のぞみクリニック築地'},
    component:() => import('../views/department/Corona')
  },
  {
    path: '/department/diet',
    meta:{title:'リベルサス | GLP-1受容体作動薬 | メディカルダイエット | のぞみクリニック築地 | 中国語対応',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/Diet')
  },
  {
    path:'/department/diet/price',
    meta:{title:'価格一覧 | 目指せ！リベルサス最安値 | のぞみクリニック築地 | 中国語対応',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/DietPrice')
  },
  {
    path:'/department/diet/guide',
    meta:{title:'診療からの流れ | のぞみクリニック築地 | 中国語対応',
      description:'当医院ではオンライン診療とご来院どちらでもGLP-1の処方を行います。',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/DietGuide')
  },
  {
    path:'/department/diet/faq',
    meta:{title:'よくある質問 | のぞみクリニック築地 | 中国語対応',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地'},
    component:() => import('../views/department/DietFAQ')
  },
  {
    path:'/department/placenta',
    meta:{title:'プラセンタ | 美肌効果 | のぞみクリニック築地 | 中国語対応',
      description:'プラセンタとは母親のお腹の中で赤ちゃんを守り、育てる役割をもった胎盤から抽出されるエキスのことです。美肌効果、肝機能の改善、更年期障害の緩和、ホルモンバランスの調整などの効果があります。'},
    component:() => import('../views/department/Placenta')
  },
  {
    path:'/department/stemsup',
    meta:{title:'エクソソーム | のぞみクリニック築地 | 中国語対応',
      description:'ヒト幹細胞培養上清液ステムサップ（StemSup）とはヒトの幹細胞を培養して、そこから分泌される活性物質：エクソソームなどを含む液のこと。脂肪由来幹細胞、歯髄由来幹細胞、臍帯由来幹細胞三つの種類があります。',},
    component:() => import('../views/department/Stemsup')
  },
  {
    path:'/department/cancer',
    meta:{title:'がん治療 | のぞみクリニック築地 | 中国語対応',
      description:'日本で著名な末期がん治療専門医である白川太郎医師は、「ヨウ素水」(HOPE HIKARI)を用いた総合療法で注目を集めています。',},
    component:() => import('../views/department/Cancer')
  },
]

const router = new VueRouter({
  //SEO需要该模式
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior(to,from,savedPosition) {
    return savedPosition ||
    {
        top: to.meta?.scrollTop || 0,
        left: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  
  from.meta?.scrollTop && (from.meta.scrollTop = window.scrollY)

  if (to.meta.title) {
    document.title = to.meta.title
    if(!document.getElementById("route_title")){
      var ometa = document.createElement('meta')
      ometa.id = "route_title"
      ometa.name = "title"
      ometa.content = to.meta.title;
      document.getElementsByTagName('head')[0].appendChild(ometa)

      var ometa_og = document.createElement('meta')
      ometa_og.id = "route_title_og"
      ometa_og.name = "og:title"
      ometa_og.content = to.meta.title;
      document.getElementsByTagName('head')[0].appendChild(ometa_og)
    }
  }
  if (to.meta.description) {
    if(!document.getElementById("route_description")){
      var ometa = document.createElement('meta')
      ometa.id = "route_description"
      ometa.name = "description"
      ometa.content = to.meta.description;
      document.getElementsByTagName('head')[0].appendChild(ometa)

      var ometa_og = document.createElement('meta')
      ometa_og.id = "route_description_og"
      ometa_og.name = "og:description"
      ometa_og.content = to.meta.description;
      document.getElementsByTagName('head')[0].appendChild(ometa_og)
      
      
    }
  }

  return next()
})

export default router
